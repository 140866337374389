import type { OverflowNavItemProps } from '@aurora/shared-client/components/common/OverflowNav/OverflowNav';
import OverflowNav from '@aurora/shared-client/components/common/OverflowNav/OverflowNav';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import type {
  MessageConstraints,
  MessageSorts
} from '@aurora/shared-generated/types/graphql-schema-types';
import {
  ConversationStyle,
  SortDirection,
  VisibilityScope
} from '@aurora/shared-generated/types/graphql-schema-types';
import type { EndUserPages, EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import { getAsEnum } from '@aurora/shared-utils/helpers/objects/EnumHelper';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import useTranslation from '../../useTranslation';

interface MessageListTabsProps {
  /**
   * The initially active tab.
   */
  activeTab: MessageListTabItem;
  /**
   * The list of constraints to display in the tab.
   */
  items: MessageListTabItem[];
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * Callback function when a tab is selected.
   *
   * @callback
   * @param item - the selected tab `MessageListTabItem`
   */
  onTabChange?: (item: MessageListTabItem) => void;
  /**
   * The key to be added as a query parameter to track the last selected tab
   */
  queryParamKey?: EndUserQueryParams;
  /**
   * Whether to record every tab change in router history.
   */
  recordHistory?: boolean;
  /**
   * Whether to change the route on tab click.
   */
  disableRoute?: boolean;
  /**
   * Whether or not the main wrap has a border.
   */
  useBottomBorder?: boolean;
  /**
   * The conversation style of messages being displayed in the list.
   */
  conversationStyle?: ConversationStyle;
}

export enum MessageListTabItem {
  MOST_RECENT = 'mostRecent',
  MOST_KUDOED = 'mostKudoed',
  MOST_REPLIES = 'mostReplies',
  MOST_VIEWED = 'mostViewed',
  TRENDING = 'trending',
  NEWEST = 'newest',
  NO_REPLIES = 'noReplies',
  NO_SOLUTIONS = 'noSolutions',
  SOLUTIONS = 'solutions',
  MOST_RECENT_USER_CONTENT = 'mostRecentUserContent',
  DRAFT = 'draft',
  SPAM = 'spam',
  ABUSE = 'abuse',
  TAGS = 'tags',
  MODERATION = 'moderation',
  NEWEST_OCCASIONS = 'newestOccasions',
  GUIDES = 'guides',
  ARCHIVES = 'archives'
}

export interface MessageListTabItemDefinition {
  constraints: MessageConstraints;
  sorts: MessageSorts;
  label?: string;
}

export const messageListTabItemMap: Record<MessageListTabItem, MessageListTabItemDefinition> = {
  [MessageListTabItem.MOST_RECENT]: {
    constraints: {
      depth: { eq: 0 }
    },
    sorts: { conversationLastPostingActivityTime: { direction: SortDirection.Desc } },
    label: `sortBy${MessageListTabItem.MOST_RECENT}`
  },
  [MessageListTabItem.MOST_RECENT_USER_CONTENT]: {
    constraints: {},
    sorts: { lastPublishTime: { direction: SortDirection.Desc } },
    label: `sortBy${MessageListTabItem.MOST_RECENT_USER_CONTENT}`
  },
  [MessageListTabItem.MOST_KUDOED]: {
    constraints: {
      kudosSumWeight: { gt: 0 }
    },
    sorts: { kudosSumWeight: { direction: SortDirection.Desc } },
    label: `sortBy${MessageListTabItem.MOST_KUDOED}`
  },
  [MessageListTabItem.MOST_REPLIES]: {
    constraints: {
      depth: { eq: 0 },
      repliesCount: { gt: 0 }
    },
    sorts: { repliesCount: { direction: SortDirection.Desc } },
    label: `sortBy${MessageListTabItem.MOST_REPLIES}`
  },
  [MessageListTabItem.MOST_VIEWED]: {
    constraints: {},
    sorts: { viewsCount: { direction: SortDirection.Desc } },
    label: `sortBy${MessageListTabItem.MOST_VIEWED}`
  },
  [MessageListTabItem.NEWEST]: {
    constraints: {
      depth: { eq: 0 }
    },
    sorts: { postTime: { direction: SortDirection.Desc } },
    label: `sortBy${MessageListTabItem.NEWEST}`
  },
  [MessageListTabItem.TRENDING]: {
    constraints: {
      depth: { eq: 0 }
    },
    sorts: { popularity: { direction: SortDirection.Desc } },
    label: `sortBy${MessageListTabItem.TRENDING}`
  },
  [MessageListTabItem.NEWEST_OCCASIONS]: {
    constraints: {
      depth: { eq: 0 }
    },
    sorts: { occasionStartTime: { direction: SortDirection.Desc } },
    label: `sortBy${MessageListTabItem.NEWEST_OCCASIONS}`
  },
  [MessageListTabItem.NO_REPLIES]: {
    constraints: { repliesCount: { eq: 0 } },
    sorts: { lastPublishTime: { direction: SortDirection.Desc } }
  },
  [MessageListTabItem.NO_SOLUTIONS]: {
    constraints: {
      depth: { eq: 0 },
      conversationSolved: { eq: false },
      conversationStyle: { eq: ConversationStyle.Forum }
    },
    sorts: { lastPublishTime: { direction: SortDirection.Desc } }
  },
  [MessageListTabItem.SOLUTIONS]: {
    constraints: {
      conversationStyle: { eq: ConversationStyle.Forum }
    },
    sorts: { lastPublishTime: { direction: SortDirection.Desc } }
  },
  [MessageListTabItem.DRAFT]: {
    constraints: { visibilityScope: { eq: VisibilityScope.PublicAndDraft } },
    sorts: { postTime: { direction: SortDirection.Desc } }
  },
  [MessageListTabItem.SPAM]: {
    constraints: {},
    sorts: {}
  },
  [MessageListTabItem.ABUSE]: {
    constraints: {},
    sorts: {}
  },
  [MessageListTabItem.MODERATION]: {
    constraints: {},
    sorts: {}
  },
  [MessageListTabItem.TAGS]: {
    constraints: {},
    sorts: {}
  },
  [MessageListTabItem.GUIDES]: {
    constraints: {},
    sorts: {}
  },
  [MessageListTabItem.ARCHIVES]: {
    constraints: {},
    sorts: {}
  }
};

/**
 * Displays a configurable list of constraints that can be applied to a list of messages.
 * Currently only supports constraint by kudos sum weight and reply count and sorting by post time, kudos sum weight
 * and replies count; other constraints, filters and sorts will be added as needed.
 *
 * @constructor
 *
 * @author Martin Sandoval
 */
const MessageListTabs: React.FC<React.PropsWithChildren<MessageListTabsProps>> = ({
  activeTab,
  items,
  className,
  onTabChange = (): Promise<void> => null,
  queryParamKey,
  recordHistory,
  disableRoute = false,
  useBottomBorder = true,
  conversationStyle
}) => {
  const cx = useClassNameMapper();
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_LIST_TABS
  );
  const { router } = useEndUserRoutes();

  if (textLoading) {
    return null;
  }

  const { route, params, query } = router.getCurrentRouteAndParams();

  function renderNavItems(): Array<OverflowNavItemProps<EndUserPages, EndUserQueryParams>> {
    return items.map(tab => {
      // create a new query object for each tab to prevent updating the query
      // object that would be shared by each tab and create invalid links
      const finalQuery = {
        ...query,
        [queryParamKey]: tab
      };
      return {
        title: formatMessage(tab, { value: conversationStyle }),
        eventKey: tab,
        ariaLabel: messageListTabItemMap[tab]?.label
          ? formatMessage(messageListTabItemMap[tab].label)
          : formatMessage('otherTabs', { tab, conversationStyle }),
        route: !disableRoute
          ? {
              route,
              params,
              query: queryParamKey ? finalQuery : query,
              options: { scroll: false }
            }
          : null,
        recordHistory
      };
    });
  }

  return (
    <OverflowNav<EndUserPages, EndUserQueryParams>
      activeTab={activeTab}
      className={cx(className, 'lia-g-my-0')}
      navLinkClassName={cx('lia-g-pt-15')}
      navItems={renderNavItems()}
      onSelect={tab => onTabChange(getAsEnum(tab, MessageListTabItem))}
      useBottomBorder={useBottomBorder}
    />
  );
};

export default MessageListTabs;
